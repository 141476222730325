import * as React from 'react';
import './sub-header.css';
import BlogPostCardsComponent from '../../components/blog-post-cards/blog-post-cards.component';

class SubHeaderComponent extends React.Component {
  render() {
    // const content = this.props.content;
    return (
      <>

        <div className="subHeader">

          <BlogPostCardsComponent />

        </div>
      </>
    );
  }
}

export default SubHeaderComponent;
