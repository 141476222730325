import * as React from 'react';

import folivora2 from '../../images/folivora2.png';
import { Card, CardContent, Typography, Grow, Button } from '@mui/material';
import './bettersnaptool.page.css';
import bst1 from '../../images/bst1.png';
import bst2 from '../../images/bst2.png';
import bst3 from '../../images/bst3.png';
import bst4 from '../../images/bst4.png';
import bst5 from '../../images/bst5.png';

import bst_s1 from '../../images/BetterSnapTool_s1.jpg';
import bst_s2 from '../../images/BetterSnapTool_s2.jpg';
import bst_s3 from '../../images/BetterSnapTool_s3.jpg';
import bst_s4 from '../../images/BetterSnapTool_s4.jpg';
import bst_s5 from '../../images/BetterSnapTool_s5.jpg';

import bsticon from '../../images/bsticon.png';
import ThumbnailboxComponent from '../../components/thumbnailbox/thumbnailbox.component';
import { Launch } from '@mui/icons-material';

class BetterSnapToolPage extends React.Component<any, any> {
  render() {
    // const content = this.props.content;
    return (
      <div className="bettersnaptoolPage defaultPageLayout defaultGrid">
        <img src={folivora2} className="logo" />
        <Grow in={true} {...(true ? { timeout: 1000 } : {})}>
          <Card className="firstContentBlock">
            <CardContent className="centerCardContent">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={bsticon}
                  style={{ height: '50px', marginRight: '10px' }}
                />

                <h1 className="lightSectionTitle">BetterSnapTool</h1>
              </div>
              <div>
                <div className="descriptionHeader">
                  <Typography component="p">
                    BetterSnapTool allows you to easily manage your window
                    positions and sizes by either dragging them to one of the
                    screen's corners or to the top, left or right side of the
                    screen. This enables you to easily maximize your windows,
                    position them side by side or even resize them to quarters
                    of the screen. If you need even more than those standard
                    snap areas, BetterSnapTool now allows you to create your
                    own, custom snap areas anywhere on your display.
                    <br />
                    <br />
                    Other features include: custom keyboard shortcuts,
                    customizing clicks on window buttons, moving/resizing
                    windows by holding a key and many more.
                  </Typography>
                </div>
              </div>
              <div
                style={{
                  margin: '0 auto',
                  marginTop: '20px',
                  marginBottom: '20px',
                }}
              >
                <ThumbnailboxComponent
                  fullsizeImage={bst_s1}
                  thumbnailImage={bst_s1}
                  className="betterSnapToolThumbnails"
                />
                <ThumbnailboxComponent
                  fullsizeImage={bst_s2}
                  thumbnailImage={bst_s2}
                  className="betterSnapToolThumbnails"
                />
                <ThumbnailboxComponent
                  fullsizeImage={bst_s3}
                  thumbnailImage={bst_s3}
                  className="betterSnapToolThumbnails"
                />
                <ThumbnailboxComponent
                  fullsizeImage={bst_s4}
                  thumbnailImage={bst_s4}
                  className="betterSnapToolThumbnails"
                />
                <ThumbnailboxComponent
                  fullsizeImage={bst_s5}
                  thumbnailImage={bst_s5}
                  className="betterSnapToolThumbnails"
                />
              </div>
              <a
                style={{ margin: '0 auto' }}
                href="https://itunes.apple.com/us/app/bettersnaptool/id417375580?mt=12"
                target="_blank"
              >
                <Button variant="contained" color="primary">
                  <Launch style={{ marginRight: '10px' }} />
                  Show in Mac App Store
                </Button>
              </a>
            </CardContent>
          </Card>
        </Grow>
        <Grow in={true} {...(true ? { timeout: 1000 } : {})}>
          <Card className="secondContentBlock">
            <CardContent className="betterSnapToolAppScreenshots">
              <img src={bst1} width="100%" />

              <img src={bst2} width="100%" />

              <img src={bst3} width="100%" />

              <img src={bst4} width="100%" />

              <img src={bst5} width="100%" />
            </CardContent>
          </Card>
        </Grow>
      </div>
    );
  }
}

export default BetterSnapToolPage;
