import * as React from 'react';

import './start.page.css';
import folivora2 from '../../images/folivora2.png';
import {
  List,
  ListItem,
  ListItemText,
  Avatar,
  Card,
  CardContent,
  Typography,
  Grow,
  Button,
} from '@mui/material';

import ThumbnailboxComponent from '../../components/thumbnailbox/thumbnailbox.component';

import btt_touchbar from '../../images/newui_touchbar.png';
import btt_tp_gestures from '../../images/newui_touchpad.png';
import btt_actions from '../../images/bettertouchtoolactions.png';
import btt_actions_thumb from '../../images/bettertouchtoolactions_thumbnail.png';

import btt_tb_t from '../../images//bttscreens/newui_touchbar@0,25x.jpg';
import btt_tb_s from '../../images//bttscreens//newui_touchbar@0,5x.jpg';

import btt_kb_t from '../../images//bttscreens/newui_keyboard@0,25x.jpg';
import btt_kb_s from '../../images//bttscreens/newui_keyboard@0,5x.jpg';

import btt_tp_t from '../../images//bttscreens/newui_touchpad@0,25.jpg';
import btt_tp_s from '../../images//bttscreens/newui_touchpad@0,5.jpg';

import btt_tools_t from '../../images//bttscreens/tools@0,1x.jpg';
import btt_tools_s from '../../images//bttscreens/tools@0,5x_2.jpg';


import drawings_t from '../../images//bttscreens/drawings@0,1x.jpg';
import drawings_s from '../../images//bttscreens/drawings@0,5x.jpg';

import menus_t from '../../images//bttscreens/menus@0,1x.jpg';
import menus_s from '../../images//bttscreens/menus@0,5x.jpg';

import wiswi from '../../images//bttscreens/windowswitcher.jpg';

import menu1 from '../../images//bttscreens/menu1.jpg';
import menu2 from '../../images//bttscreens/menu2.jpg';

import {
  LaptopMac,
  Crop169,
  PanTool,
  CallToAction,
  Looks,
  Gesture,
  Mouse,
  Keyboard,
  ShoppingCart,
  Phonelink,
  GetApp,
  Apps,
  LibraryAdd,
  DonutSmallRounded,
} from '@mui/icons-material';
import withRouter from '../../withRouter';

class StartPage extends React.Component<any, any> {
  render() {
    // const content = this.props.content;
    return (
      <div className="startPage defaultPageLayout defaultGrid">
        <img src={folivora2} className="logo" />

        {false ? (
          <Grow in={true} {...(true ? { timeout: 1000 } : {})}>
            <Card className="blackFriday">
              <CardContent style={{ minHeight: "50px", textAlign: "center" }}>
                <Typography color="inherit" component="h2">
                🚨 Black Friday! 25% off on standard BetterTouchTool licenses, 33% on lifetime licenses. Discount will automatically be applied! 🚨
                </Typography>
              </CardContent>
            </Card>
          </Grow>
        ) : (
          ""
        )}

        <Grow in={true} {...(true ? { timeout: 1000 } : {})}>
          <Card className="firstContentBlock">
            <CardContent
              className="centerCardContent"
              style={{
                textAlign: 'center',
                display: 'block',
                margin: 'auto',
              }}
            >
              <h1 className="lightSectionTitle">BetterTouchTool</h1>
              <Typography component="p" style={{ fontSize: '17px' }}>
                BetterTouchTool is a great, feature packed app that allows you
                to customize various input devices on your Mac.
                <br />
                Currently BetterTouchTool supports:
              </Typography>
              <div className="triggers">
                <List>
                  <ListItem>
                    <Avatar>
                      <LaptopMac />
                    </Avatar>
                    <ListItemText
                      style={{ marginLeft: '4px' }}
                      primary="Macbook Trackpad"
                    />
                  </ListItem>
                  <ListItem>
                    <Avatar>
                      <Crop169 />
                    </Avatar>
                    <ListItemText
                      style={{ marginLeft: '4px' }}
                      primary="Magic Trackpad 1,2 & 3"
                    />
                  </ListItem>
                  <ListItem>
                    <Avatar>
                      <PanTool />
                    </Avatar>
                    <ListItemText
                      style={{ marginLeft: '4px' }}
                      primary="Magic Mouse 1, 2 & 3"
                    />
                  </ListItem>
                  <ListItem>
                    <Avatar>
                      <CallToAction />
                    </Avatar>
                    <ListItemText
                      style={{ marginLeft: '4px' }}
                      primary="Touch Bar customization"
                    />
                  </ListItem>
                  <ListItem>
                    <Avatar>
                      <Looks />
                    </Avatar>
                    <ListItemText
                      style={{ marginLeft: '4px' }}
                      primary="Siri/Apple Remote"
                    />
                  </ListItem>
                  <ListItem>
                    <Avatar>
                      <Apps />
                    </Avatar>
                    <ListItemText
                      style={{ marginLeft: '4px' }}
                      primary="Elgato Stream Deck"
                    />
                  </ListItem>
                  <ListItem>
                    <Avatar>
                      <DonutSmallRounded />
                    </Avatar>
                    <ListItemText
                      style={{ marginLeft: '4px' }}
                      primary="Completely Customizable Floating Widgets / Menus"
                    />
                  </ListItem>
                </List>

                <List>
                  <ListItem>
                    <Avatar>
                      <Gesture />
                    </Avatar>
                    <ListItemText
                      style={{ marginLeft: '4px' }}
                      primary="Normal Mouse Gestures"
                    />
                  </ListItem>
                  <ListItem>
                    <Avatar>
                      <Mouse />
                    </Avatar>
                    <ListItemText
                      style={{ marginLeft: '4px' }}
                      primary="Normal Mouse Buttons"
                    />
                  </ListItem>

                  <ListItem>
                    <Avatar>
                      <Keyboard />
                    </Avatar>
                    <ListItemText
                      style={{ marginLeft: '4px' }}
                      primary="Keyboard Shortcuts"
                    />
                  </ListItem>
                  <ListItem>
                    <Avatar>
                      <Keyboard />
                    </Avatar>
                    <ListItemText
                      style={{ marginLeft: '4px' }}
                      primary="Key Sequences / Text Triggers"
                    />
                  </ListItem>
                  <ListItem>
                    <Avatar>
                      <Phonelink />
                    </Avatar>
                    <ListItemText
                      style={{ marginLeft: '4px' }}
                      primary="BTT Remote (iOS) Triggers"
                    />
                  </ListItem>
               
                  <ListItem>
                    <Avatar>
                      <LibraryAdd />
                    </Avatar>
                    <ListItemText
                      style={{ marginLeft: '4px' }}
                      primary="Full Menubar Customization"
                    />
                  </ListItem>
                </List>
              </div>

              <div
                style={{
                  textAlign: 'center',
                  marginTop: '10px',
                  display: 'block',
                  margin: 'auto',
                }}
              >
                <a
                  style={{ margin: '5px' }}
                  href="https://folivora.ai/releases/BetterTouchTool.zip"
                  target="_blank"
                >
                  <Button variant="contained" color="primary">
                    <GetApp style={{ marginRight: '10px' }} />
                    Download Trial (45 days)
                  </Button>
                </a>
                <a
                  style={{ margin: '5px' }}
                  onClick={() => {
                    this.props.router.navigate('/buy');
                  }}
                >
                  <Button variant="contained" color="primary">
                    <ShoppingCart style={{ marginRight: '10px' }} />
                    Buy a License
                  </Button>
                </a>
              </div>
            </CardContent>
          </Card>
        </Grow>
        <Grow in={true} {...(true ? { timeout: 1000 } : {})}>
          <Card className="secondContentBlock">
            <CardContent className="centerCardContent">
              <div
                style={{
                  display: 'block',
                  margin: 'auto',
                  marginTop: '10px',
                  marginBottom: '10px',
                }}
              >
                <ThumbnailboxComponent
                  key="1"
                  fullsizeImage={btt_tb_s}
                  thumbnailImage={btt_tb_t}
                  className="betterTouchoolThumbnails"
                  fullsizeImageStyle={{
                    objectFit: 'contain',
                    width: '100%',
                    padding: '10px',
                  }}
                />
                <ThumbnailboxComponent
                  key="2"
                  fullsizeImage={btt_kb_s}
                  thumbnailImage={btt_kb_t}
                  className="betterTouchoolThumbnails"
                  fullsizeImageStyle={{
                    objectFit: 'contain',
                    width: '100%',
                    padding: '10px',
                  }}
                />
                <ThumbnailboxComponent
                  key="3"
                  fullsizeImage={btt_tp_s}
                  thumbnailImage={btt_tp_t}
                  className="betterTouchoolThumbnails"
                  fullsizeImageStyle={{
                    objectFit: 'contain',
                    width: '100%',
                    padding: '10px',
                  }}
                />
                <ThumbnailboxComponent
                  key="4"
                  fullsizeImage={btt_tools_s}
                  thumbnailImage={btt_tools_t}
                  className="betterTouchoolThumbnails"
                  fullsizeImageStyle={{
                    objectFit: 'contain',
                    width: '100%',
                    padding: '10px',
                  }}
                /><br/>
                 <ThumbnailboxComponent
                  key="4"
                  fullsizeImage={drawings_s}
                  thumbnailImage={drawings_t}
                  className="betterTouchoolThumbnails"
                  fullsizeImageStyle={{
                    objectFit: 'contain',
                    width: '100%',
                    padding: '10px',
                  }}   />
                  <ThumbnailboxComponent
                  key="4"
                  fullsizeImage={menus_s}
                  thumbnailImage={menus_t}
                  className="betterTouchoolThumbnails"
                  fullsizeImageStyle={{
                    objectFit: 'contain',
                    width: '100%',
                    padding: '10px',
                  }}
                />
              </div>
            </CardContent>
          </Card>
        </Grow>
        <Grow in={true} {...(true ? { timeout: 1000 } : {})}>
          <Card className="thirdContentBlock">
            <CardContent className="centerCardContent">
              <div
                className="triggers"
                style={{ width: '90%', padding: '30px', display: 'block' }}
              >
                <div>
                  <Typography component="p" style={{ fontSize: '17px' }}>
                    You can use any of the supported trigger/input types and
                    choose from a large and always growing list of actions to
                    automate almost any task. Actions can be chained to handle
                    complicated things.
                    <br />
                    Additionally BTT includes various handy tools that every Mac
                    owner should have. For example:
                  </Typography>
                  <ul style={{ fontWeight: 'bold' }}>
                    <li>
                      Customizable Window Snapping & Resizing & Moving tools
                    </li>
                    <li>Screenshot Tool & Editor</li>
                    <li>Clipboard Manager</li>
                    <li>Window Switcher</li>
                    <li>Completely Customizable Floating Menus</li>
                    <li>
                      ...many more. In case you find something you can't do with
                      BTT, please let me know.
                    </li>
                  </ul>
                </div>
                <div
  style={{
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)', // Three columns with equal width
    gap: '10px', // Optional: Adds spacing between items
    margin: 'auto',
    marginTop: '10px',
    marginBottom: '10px',
  }}
>
  <ThumbnailboxComponent
    fullsizeImage={wiswi}
    thumbnailImage={wiswi}
    style={{ maxWidth: '200px', margin: '0 auto' }}
  />
  <ThumbnailboxComponent
    fullsizeImage={menu1}
    thumbnailImage={menu1}
    style={{ maxWidth: '200px', margin: '0 auto' }}
  />
  <ThumbnailboxComponent
    fullsizeImage={menu2}
    thumbnailImage={menu2}
    style={{ maxWidth: '200px', margin: '0 auto' }}
  />
  <ThumbnailboxComponent
    fullsizeImage={btt_actions}
    thumbnailImage={btt_actions_thumb}
    fullsizeImageStyle={{ maxWidth: '50%' }}
    style={{ maxWidth: '200px', margin: '0 auto' }}
  />
</div>

              </div>
            </CardContent>
          </Card>
        </Grow>
        <Grow in={true} {...(true ? { timeout: 1000 } : {})}>
          <Card className="thirdContentBlock">
            <CardContent className="centerCardContent">
              <div className="twoColumns5050" style={{ marginTop: '30px' }}>
                <div style={{ fontSize: '11px' }}>
                  <img src={btt_touchbar} style={{ width: '100%' }} />
                  Note: The preset shown here is the GoldenChaos Preset
                  available via{' '}
                  <a href="https://community.folivora.ai">
                    https:/t /community.folivora.ai
                  </a>
                  <br />
                  The crypto currency scripts in the screenshot were generated
                  using:
                  <a href="https://chrislennon.github.io/Crypto-Touchbar-App/">
                    https://chrislennon.github.io/Crypto-Touchbar-App
                  </a>
                  . The on-screen Touch Bar is displayed using&nbsp;
                  <a href="https://github.com/sindresorhus/touch-bar-simulator">
                    https://github.com/sindresorhus/touch-bar-simulator
                  </a>
                </div>

                <img src={btt_tp_gestures} style={{ width: '100%' }} />
              </div>
            </CardContent>
          </Card>
        </Grow>
      </div>
    );
  }
}

export default withRouter(StartPage);
