import * as React from "react";
import "./App.css";
import "../node_modules/prismjs/themes/prism-tomorrow.css";
import StartPage from "./pages/start/start.page";
import PurchasePage from "./pages/purchase/purchase.page";
import CssBaseline from "@mui/material/CssBaseline";
import Headerelement from "./layout/header/header";
import SubHeaderelement from "./layout/sub-header/sub-header";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Footerelement from "./layout/footer/footer";
import DownloadsPage from "./pages/downloads/downloads.page";
import BetterSnapToolPage from "./pages/bettersnaptool/bettersnaptool.page";
import BTTRemotePage from "./pages/bttremote/bttremote.page";
import KeyboardCleanToolPage from "./pages/keyboardcleantool/keyboardcleantool.page";
import BlogPage from "./pages/blog/blog.page";
import ImprintPage from "./pages/imprint/imprint.page";
import DocsPage from "./pages/docs/docs.page";
import IssuesPage from "./pages/issues/issues.page";
import AboutMePage from "./pages/about-me/about-me.page";
import PrivacyPage from "./pages/privacy/privacy.page";

import useMediaQuery from "@mui/material/useMediaQuery";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { PaletteMode } from "@mui/material";
export default function AppC() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const getDesignTokens = (mode: PaletteMode) => ({
    typography: {
      fontFamily: 'Helvetica Neue',
      fontWeightRegular: 400,
      fontWeightMedium: 400,
      fontWeightLight: 300,
      fontWeightBold: 700,
    },
    palette: {
      mode,
      ...(mode === "light"
        ? {
          
            text: {
              primary: "#000",
              secondary: "rgb(64, 64, 64)",
              hint: "#ffa644",
              disabled: "#c2c2c2",
            },
            common: {
              white: "#000",
              black: "#fff",
            },
            primary: {
              light: "rgb(179, 171, 193)",
              main: "#573d7c",
              dark: "rgb(179, 171, 193)",
              contrastText: "#fff",
            },
            secondary: {
              light: "rgba(255, 255, 255, 0.98)",
              main: "rgb(54, 43, 74)",
              dark: "#573d7c;",
              contrastText: "#fff",
            },
          }
        : {
            // palette values for dark mode
            text: {
              primary: "#fff",
              secondary: "rgb(230, 230, 230);",
              hint: "#ffa644",
              disabled: "#c2c2c2",
            },
            common: {
              white: "#000",
              black: "#fff",
            },
            primary: {
              light: "rgb(179, 171, 193);",
              main: "#7d73e8",
              dark: "rgb(179, 171, 193);",
              contrastText: "#fff",
            },
            secondary: {
              light: "rgba(40, 40, 40, 1);",
              main: "rgb(54, 43, 74);",
              dark: "#a297b1",
              contrastText: "#000",
            },
          }),
    },
  });
  const theme = React.useMemo(
    () => createTheme(getDesignTokens(prefersDarkMode ? "dark" : "light")),
    [prefersDarkMode]
  );

  return (
    <div>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <div className="mainContainer defaultGrid">
            <CssBaseline />
            <Headerelement />

            <main>
              <Routes>
                <Route path="/" element={<SubHeaderelement />} />
                <Route path="/blog" element={<SubHeaderelement />} />
                <Route path="/blog/post/*" element={<SubHeaderelement />} />

              </Routes>

              <Routes>
                <Route path="/" element={<StartPage />} />
                <Route path="/buy" element={<PurchasePage />} />
                <Route path="/btt-licenses" element={<PurchasePage />} />
                <Route path="/downloads" element={<DownloadsPage />} />
                <Route
                  path="/bettersnaptool"
                  element={<BetterSnapToolPage />}
                />
                <Route path="/bttremote" element={<BTTRemotePage />} />
                <Route path="/downloads" element={<DownloadsPage />} />
                <Route
                  path="/keyboardcleantool"
                  element={<KeyboardCleanToolPage />}
                />

                <Route path="/releases" element={<DownloadsPage />} />
                <Route path="/about-me" element={<AboutMePage />} />
                <Route path="/privacy" element={<PrivacyPage />} />
                <Route path="/imprint" element={<ImprintPage />} />
                <Route path="/docs" element={<DocsPage />} />
                <Route path="/issues" element={<IssuesPage />} />
                <Route path="/bugs" element={<IssuesPage />} />
                <Route
                  path="/blog/:unknownPageName/comments/*"
                  element={<BlogPage showComments={true} />}
                />
                <Route
                  path="/blog/page/:pageNumber/comments/*"
                  element={<BlogPage showComments={true} />}
                />
                <Route
                  path="/blog/post/:postId/comments/*"
                  element={<BlogPage showComments={true} />}
                />

                <Route path="/blog/page/:pageNumber/*" element={<BlogPage />} />
                <Route path="/blog/post/:postId/*" element={<BlogPage />} />
                <Route path="/blog/:unknownPageName/*" element={<BlogPage />} />
                <Route path="/blog" element={<BlogPage />} />
                <Route path="/:unknownPageName" element={<BlogPage />} />
              </Routes>
            </main>
            <Footerelement />
          </div>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}
