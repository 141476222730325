import * as React from 'react';
import {
  Typography,
  DialogContent,
  Dialog,
  DialogContentText,
  DialogTitle,
  Slide,
} from '@mui/material';
import codesignature from '../../../images/codesignature.png';

export default class DownloadValidationModal extends React.Component<any, any> {
  private slideTransition(props: any) {
    return <Slide direction="up" {...props} />;
  }

  render() {
    return (
      <Dialog
        TransitionComponent={this.slideTransition}
        open={true}
        onClose={() => {
          this.props.onModalClose();
        }}
        PaperProps={{ className: 'modalBackground' }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          How to verify the code signature of the download
        </DialogTitle>
        <DialogContent>
          <Typography component="p">
            If you want to verify the code signature of BetterTouchTool after
            downloading & unzipping it, use this terminal command:
          </Typography>
          <Typography
            component="p"
            style={{ fontWeight: 'bold', margin: '10px' }}
          >
            codesign -vv BetterTouchTool.app && codesign -dvvv
            BetterTouchTool.app
          </Typography>{' '}
          <Typography component="p">
            The output should contain those lines (and some more but the hashes
            change for each version):
          </Typography>{' '}
          <Typography
            component="p"
            style={{ fontWeight: 'bold', margin: '10px' }}
          >
            BetterTouchTool.app: valid on disk BetterTouchTool.app:
            <br />
            satisfies its Designated Requirement
            <br />
            Authority=Developer ID Application: folivora.AI GmbH (DAFVSXZ82P)
            <br />
            Authority=Developer ID Certification Authority
            <br />
            Authority=Apple Root CA
            <br />
          </Typography>
          <img src={codesignature} width="550px" />
          <DialogContentText />
        </DialogContent>
      </Dialog>
    );
  }
}
