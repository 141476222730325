import * as React from 'react';
import './downloads.page.css';
import btticon from '../../images/btticon.png';
import bsticon from '../../images/bsticon.png';
import keyboardcleantoolicon from '../../images/keyboardcleantool.png';

import folivora2 from '../../images/folivora2.png';
import { Card, CardContent, Grow, Typography, Button } from '@mui/material';

import PreviousVersionsModal from './previous-versions/previous-versions.modal';
import DownloadValidationModal from './validation/validation.modal';
import { Launch, GetApp, Fingerprint } from '@mui/icons-material';

export default class DownloadsPage extends React.Component<any, any> {
  state = {
    showPreviousVersionsModal: false,
    showVerificationModal: false,
    blackFriday: false,

  };

  private onShowPreviousVersions() {
    this.setState({
      showPreviousVersionsModal: true,
    });
  }

  private onShowVerification() {
    this.setState({
      showVerificationModal: true,
    });
  }

  private onHandleModalClose = () => {
    this.setState({
      showPreviousVersionsModal: false,
      showVerificationModal: false,
    });
  };

  render() {
    const { showPreviousVersionsModal, showVerificationModal } = this.state;

    return (
      <div className="downloadsPage defaultPageLayout defaultGrid">
        <img src={folivora2} className="logo" />

        {this.state.blackFriday === true ? (
          <Grow in={true} {...(true ? { timeout: 1000 } : {})}>
            <Card className="blackFriday">
              <CardContent style={{ minHeight: "50px", textAlign: "center" }}>
                <Typography color="inherit" component="h2">
                  Black Friday! 25% off on all BetterTouchTool licenses. Coupon
                  code: <b>BTT_BLACK_FRIDAY_2023</b>
                </Typography>
              </CardContent>
            </Card>
          </Grow>
        ) : (
          ""
        )}

        <Grow in={true} {...(true ? { timeout: 1000 } : {})}>
          <Card className="firstContentBlock">
            <CardContent className="downloadBlock">
              <img src={btticon} width="150px" />
              <div>
                <Typography component="h3"  variant="h5" color="inherit">
                  BetterTouchTool
                </Typography>
                <Typography component="p" color="inherit">
                  Here you can download a <strong>45 day trial</strong> of
                  BetterTouchTool.
                  <br />
                  BetterTouchTool is my main app and offers tons of
                  functionality to automate your system and customize various
                  input devices. (E.g. define Keyboard Shortcuts, TouchBar
                  customization, Trackpad & Magic Mouse & Normal Mouse Gestures,
                  Siri Remote & Apple Remote, ...)
                </Typography>
              </div>
              <div className="downloadButtonArea">
                <a href="https://folivora.ai/releases/BetterTouchTool.zip">
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ width: '100%' }}
                  >
                    <GetApp style={{ marginRight: '10px' }} />
                    Download Latest
                  </Button>
                </a>
                <a href="https://updates.folivora.ai/bettertouchtool_release_notes.html">
                  Releasenotes
                </a>
               
                <a href="https://folivora.ai/releases/">
                <Button
                 >
                  <GetApp style={{ marginRight: '10px' }} />
                  Previous Versions
                </Button>
                </a>
                {showVerificationModal && (
                  <DownloadValidationModal
                    onModalClose={this.onHandleModalClose}
                  />
                )}
                <Button
                  variant="contained"
                  onClick={() => {
                    this.onShowVerification();
                  }}>
                  <Fingerprint style={{ marginRight: '10px' }} />
                  Verify codesignature
                </Button>
              </div>
            </CardContent>
          </Card>
        </Grow>
        <Grow in={true} {...(true ? { timeout: 1000 } : {})}>
          <Card className="secondContentBlock">
            <CardContent className="downloadBlock">
              <div />

              <div>
                <Typography component="h3" variant="h5" color="inherit">
                  BTT Remote
                </Typography>
                <Typography component="p" color="inherit">
                  BTT Remote is a little companion app for BetterTouchTool. It
                  runs on your <strong>iPhone or iPad</strong> and allows you to
                  remote control your Mac. It is free to use if you have
                  purchased BetterTouchTool.
                </Typography>
              </div>
              <div className="downloadButtonArea">
                <a
                  href="https://itunes.apple.com/us/app/btt-remote-mouse-trackpad/id561676304?mt=8"
                  target="_blank"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ width: '100%' }}
                  >
                    <Launch style={{ marginRight: '10px' }} />
                    Show in iOS App Store
                  </Button>
                </a>
              </div>
            </CardContent>
          </Card>
        </Grow>
        <Grow in={true} {...(true ? { timeout: 1000 } : {})}>
          <Card className="thirdContentBlock">
            <CardContent className="downloadBlock">
              <img src={bsticon} width="150px" />

              <div>
                <Typography component="h3"  variant="h5" color="inherit">
                  BetterSnapTool
                </Typography>
                <Typography component="p" color="inherit">
                  BetterSnapTool is a simple & lightweight tool to easily move
                  and resize windows. It's currently only available via the Mac
                  App Store. <br />
                  BetterTouchTool includes the same window snapping technology
                  as BetterSnapTool, however due to it's broader scope it's more
                  complicated and may use more resources.
                </Typography>
              </div>
              <div className="downloadButtonArea">
                <a
                  href="https://itunes.apple.com/us/app/bettersnaptool/id417375580?mt=12"
                  target="_blank"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ width: '100%' }}
                  >
                    <Launch style={{ marginRight: '10px' }} />
                    Show in Mac App Store
                  </Button>
                </a>
              </div>
            </CardContent>
          </Card>
        </Grow>

        <Grow in={true} {...(true ? { timeout: 1000 } : {})}>
          <Card className="fourthContentBlock">
            <CardContent className="downloadBlock">
              <img src={keyboardcleantoolicon} width="150px" />

              <div>
                <Typography component="h3"  variant="h5" color="inherit">
                  KeyboardCleanTool
                </Typography>
                <Typography component="p" color="inherit">
                  KeyboardCleanTool is a super simple little tool which blocks
                  all Keyboard and TouchBar input. Apple rejected the app for
                  the Mac App Store because it's "not useful", however I often
                  use it to clean my Macbook Keyboard without producing annoying
                  input.
                </Typography>
              </div>
              <div className="downloadButtonArea">
                <a
                  href="https://folivora.ai/releases/KeyboardCleanTool.zip"
                  target="_blank"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ width: '100%' }}
                  >
                    <GetApp style={{ marginRight: '10px' }} />
                    Free Download
                  </Button>
                </a>
              </div>
            </CardContent>
          </Card>
        </Grow>
      </div>
    );
  }
}
