import * as React from 'react';

import folivora2 from '../../images/folivora2.png';
import { Card, CardContent, Typography, Grow, Button } from '@mui/material';
import './bttremote.page.css';
import bttremote from '../../images/bttremote.png';
import remotecontrol from '../../images/remotecontrol.jpg';
import trackpad from '../../images/trackpad.jpg';
import menubar from '../../images/menubar.jpg';
import keyboard from '../../images/keyboard.jpg';
import filebrowser from '../../images/filebrowser.jpg';
import ipadiphone from '../../images/ipadiphone.jpg';
import btticon from '../../images/btticon.png';

import ThumbnailboxComponent from '../../components/thumbnailbox/thumbnailbox.component';
import { Launch } from '@mui/icons-material';
class BTTRemotePage extends React.Component {
  render() {
    // const content = this.props.content;
    return (
      <div className="bttremotePage defaultPageLayout defaultGrid">
        <img src={folivora2} className="logo" />
        <Grow in={true} {...(true ? { timeout: 1000 } : {})}>
          <Card className="firstContentBlock">
            <CardContent className="centerCardContent">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={btticon}
                  style={{ height: '50px', marginRight: '10px' }}
                />

                <h1 className="lightSectionTitle">BTT Remote</h1>
              </div>{' '}
              <Typography component="p">
                BTT Remote is a little companion app for BetterTouchTool. It
                runs on your iPhone or iPad and allows you to remote control
                your Mac. It is free to use if you have purchased
                BetterTouchTool.
              </Typography>
              <img src={bttremote} className="bttremotePreview" />
            </CardContent>
          </Card>
        </Grow>
        <Grow in={true} {...(true ? { timeout: 1000 } : {})}>
          <Card className="secondContentBlock">
            <CardContent style={{ textAlign: 'center' }}>
              <a
                style={{ margin: '0 auto' }}
                href="https://itunes.apple.com/us/app/btt-remote-mouse-trackpad/id561676304?mt=8"
                target="_blank"
              >
                <Button variant="contained" color="primary">
                  <Launch style={{ marginRight: '10px' }} />
                  Show in iOS App Store
                </Button>
              </a>
            </CardContent>
          </Card>
        </Grow>
        <Grow in={true} {...(true ? { timeout: 1000 } : {})}>
          <Card className="thirdContentBlock">
            <CardContent className="bttremoteDetailsBlock">
              <ThumbnailboxComponent
                fullsizeImage={remotecontrol}
                thumbnailImage={remotecontrol}
                style={{ width: '100%' }}
              />
              <ThumbnailboxComponent
                fullsizeImage={trackpad}
                thumbnailImage={trackpad}
                style={{ width: '100%' }}
              />
              <ThumbnailboxComponent
                fullsizeImage={menubar}
                thumbnailImage={menubar}
                style={{ width: '100%' }}
              />
              <div>
                <Typography component="h2" >
                  Great Remote Control
                </Typography>
                <Typography component="p">
                  After setting up some actions in BetterTouchTool you can
                  remote control your Mac from BTT Remote on your iPhone or
                  iPad. You can define actions for almost any app on your Mac.
                  E.g. trigger arbitrary keyboard shortcuts, or use an action of
                  the ever growing list of predefined actions.
                  <br />
                  You can even assign icons to each action that will show up on
                  your iPhone/iPad!
                </Typography>
              </div>
              <div>
                <Typography component="h2" >
                  Built-In Trackpad
                </Typography>
                <Typography component="p">
                  The trackpad is an important part of the BTT Remote. It
                  supports inertial two finger scrolling , and even inertial
                  cursor movement (which allows you to move the cursor fluidly
                  over long distances with just a little swipe)
                  <br />
                  You can also configure scrolling by swiping on the edges of
                  the trackpad, or scrolling after a long tap.
                  <br />
                  You can navigate your history by scrolling left / right (in
                  most browsers) or by using a three finger swipe.
                  <br />
                  Zoom your Macs screen with a two finger pinch
                  <br />
                  Optionally automatically increase the mouse cursor size, so
                  you are able to see it while remotely moving it e.g. from your
                  couch
                </Typography>
              </div>
              <div>
                <Typography component="h2" >
                  Access almost any apps menubar
                </Typography>
                <Typography component="p">
                  Even if you haven't configured any actions in BetterTouchTool,
                  you can always access the menubar of any app you want.
                  <br />
                  This allows you to take almost full control over the apps
                  running on your Mac. Wanna set the aspect ratio in your media
                  player? - Shouldn't be a problem by accessing it's menubar.
                  Want to send your Mac sleeping? - No problem.
                </Typography>
              </div>

              <ThumbnailboxComponent
                fullsizeImage={keyboard}
                thumbnailImage={keyboard}
                style={{ width: '100%' }}
              />
              <ThumbnailboxComponent
                fullsizeImage={filebrowser}
                thumbnailImage={filebrowser}
                style={{ width: '100%' }}
              />
              <ThumbnailboxComponent
                fullsizeImage={ipadiphone}
                thumbnailImage={ipadiphone}
                style={{ width: '100%' }}
              />
              <div>
                <Typography component="h2" >
                  Built-In keyboard
                </Typography>
                <Typography component="p">
                  Use your iPhone keyboard to type text on your Mac! Or even use
                  the iPhones dictation features to dictate text to your Mac :-)
                  <br />
                  BTT Remote also allows you to access most of the special keys
                  on your Macs keyboard. You can hit the
                  play/pause/previous/next keys remotely, change the volume, the
                  brightness of your screens (internal and external displays
                  separately!) and even your keyboard brightness. Just as if
                  you'd hit them on your Macs keyboard!
                  <br />
                  No need to stand up because you forgot to turn down the
                  keyboard illumination or to change the volume before watching
                  a movie anymore!
                </Typography>
              </div>
              <div>
                <Typography component="h2" >
                  Integrated file browser / launcher
                </Typography>
                <Typography component="p">
                  Sitting on your couch and want to watch a movie on your Mac
                  (which may be connected to your TV)? <br />BTT Remote allows
                  you to remotely browse the files on your Mac and launch them
                  from your iPhone or iPad. You can open movies, applications,
                  pictures etc. It's pretty intuitive to use because it
                  basically shows you your Macs Finder sidebar. Thus all your
                  favorite folders will show up and you can browse through them.
                </Typography>
              </div>
              <div>
                <Typography component="h2" >
                  Works on your iPhone and iPad
                </Typography>
                <Typography component="p">
                  You don't need to buy BTT Remote twice, it runs out of the box
                  on your iPhone (including the iPhone X) and your iPad. You can
                  even connect multiple devices at the same time!
                  <br />
                  Also BTT Remote supports Landscape and Portrait view on both
                  devices.
                </Typography>
              </div>
              <div />
            </CardContent>
          </Card>
        </Grow>
      </div>
    );
  }
}

export default BTTRemotePage;
