import * as React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Slide,
  DialogContentText,
  Checkbox,
  FormControlLabel,
} from '@mui/material';

import MailCheck from 'react-mailcheck';

class PurchaseDialog extends React.Component<any, any> {
  private mailChangeTimeout: any;
  state = {
    licenseOwnerName: '',
    selectedQuantity: 1,
    emailTemp: '',
    email: '',
    differentLicenseOwner: false,
    licenseOwnerEmailTemp: '',

    licenseOwnerEmail: ''
  };

  private handleLicenseOwnerNameChange = (event: any) => {
    this.setState({ licenseOwnerName: event.target.value });
  };

  private handleSelectedQuantityChange = (event: any) => {
    this.setState({ selectedQuantity: parseInt(event.target.value, 10) });
  };

  private handleCheckboxChange = (event: any) => {
    console.log('event', event);
    this.setState({ differentLicenseOwner: event.target.checked });

  }

  private handleLicenseOwnerEmailAddressChange = (event: any) => {
    this.setState({ licenseOwnerEmailTemp: event.target.value });
    if (this.mailChangeTimeout) {
      clearTimeout(this.mailChangeTimeout);
    }
    let self = this;
    this.mailChangeTimeout = setTimeout(() => {
      self.setState({ licenseOwnerEmail: this.state.licenseOwnerEmailTemp });
    }, 1000);
  }

  private handleEmailAddressChange = (event: any) => {
    this.setState({ emailTemp: event.target.value });
    if (this.mailChangeTimeout) {
      clearTimeout(this.mailChangeTimeout);
    }
    let self = this;
    this.mailChangeTimeout = setTimeout(() => {
      self.setState({ email: this.state.emailTemp });
    }, 1000);
  };

  private handleEmailAddressChangeBlur = (event: any) => {
    this.setState({ email: event.target.value, emailTemp: event.target.value });
  };

  
  private handleOwnerEmailAddressChangeBlur = (event: any) => {
    this.setState({ licenseOwnerEmail: event.target.value, licenseOwnerEmailTemp: event.target.value });
  };

  private slideTransition(props: any) {
    return <Slide direction="up" {...props} />;
  }

  private handleClickMailcheck(check: any) {
    console.log('test', check);
    this.setState({ email: check.full, emailTemp: check.full });
  }

  render() {
    // const content = this.props.content;
    return (
      <Dialog
        PaperProps={{ className: 'modalBackground' }}
        style={{ marginTop: '-10%' }}
        TransitionComponent={this.slideTransition}
        open={true}
        onClose={() => {
          this.props.onPurchaseDialogNext(false, undefined);
        }}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {this.props.purchaseDialogTitle || 'Buy a License'}
        </DialogTitle>
        <DialogContent>
          <MailCheck email={this.state.email}>
            {(suggestion: { domain: any; address: any; full: any }) => (
              <>
              
   <TextField
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  autoFocus={true}
                  margin="dense"
                  data-1p-ignore={true}

                  value={this.state.emailTemp}
                  onChange={this.handleEmailAddressChange}
                  onBlur={this.handleEmailAddressChangeBlur}
                  id="licenseAndInvoice"
                  label="E-Mail (license & invoice will be sent here)"
                  type="email"
                  fullWidth={true}
                />

                <FormControlLabel
  label="Use Different License Owner E-Mail (purchasing for somebody else)"
  control={
    <Checkbox
    checked={this.state.differentLicenseOwner}
    onChange={this.handleCheckboxChange}
    />
  }
/>
                {suggestion && (
                  <div className="mailcheck">
                    Did you mean {suggestion.address}@
                    <a
                      href="#x"
                      className="mailcheck-button"
                      onClick={(event) => {
                        this.handleClickMailcheck(suggestion);
                        event.preventDefault();
                      }}
                    >
                      {suggestion.domain}
                    </a>
                    ? (if not please ignore this message)
                  </div>
                )}

{this.state.differentLicenseOwner && <TextField
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  autoFocus={true}
                  margin="dense"
                  data-1p-ignore={true}
                  value={this.state.licenseOwnerEmailTemp}
                  onChange={this.handleLicenseOwnerEmailAddressChange}
                  onBlur={this.handleOwnerEmailAddressChangeBlur}
                  id="licenseOwner"
                  label="License Owner E-Mail (Optional, only used for display purposes)"
                  type="text"
                  fullWidth={true}
                />
}
              </>
            )}
          </MailCheck>
          <TextField
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            autoFocus={false}
            margin="dense"
            value={this.state.licenseOwnerName}
            onChange={this.handleLicenseOwnerNameChange}
            id="licenseOwnerName"
            label="License Owner Name"
            type="text"
            fullWidth={true}
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            autoFocus={false}
            margin="dense"
            autoComplete='off'
            value={this.state.selectedQuantity}
            onChange={this.handleSelectedQuantityChange}
            id="numberOfLicenses"
            label="Number of Licenses (For companies buying more than one seat)"
            type="number"
            fullWidth={true}
          />
          <br />
          <br />
          <DialogContentText variant="subtitle2">
            During Checkout you will be able to provide a VAT ID if necessary.
            <br />
            After the payment has completed you'll receive an e-mail from our
            reseller Paddle.com where you can generate/download an invoice.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.props.onPurchaseDialogNext(false, undefined);
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              this.props.onPurchaseDialogNext(
                true,
                this.state.licenseOwnerName,
                this.state.selectedQuantity,
                this.state.emailTemp,
                this.state.differentLicenseOwner,
                this.state.licenseOwnerEmailTemp
              );
            }}
            variant="contained"
            color="primary"
          >
            Continue to Checkout
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default PurchaseDialog;
