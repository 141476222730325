import AppC from "./AppC.tsx";
import "./index.css";
import registerServiceWorker from "./registerServiceWorker";

import { hydrate } from "react-dom";

import { createRoot } from "react-dom/client";

import "./index.css";

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<AppC />, rootElement);
} else {
  createRoot(document.getElementById("root")).render(<AppC />);
}
registerServiceWorker();
