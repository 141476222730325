import * as React from 'react';
import { Typography, CardContent, Card, Grow, Button } from '@mui/material';
import './issues.page.css';
import folivora2 from '../../images/folivora2.png';
import { Launch } from '@mui/icons-material';

export interface IssuesPageProps {}

export default class IssuesPage extends React.Component<IssuesPageProps, any> {
  render() {
    return (
      <div className="issuesPage defaultPageLayout defaultGrid">
        <img src={folivora2} className="logo" />
        <Grow in={true} {...(true ? { timeout: 1000 } : {})}>
          <Card className="firstContentBlock">
            <CardContent>
              <h1 className="lightSectionTitle">
                Issues / Feature Requests / Questions
              </h1>

              <Typography component="p" color="inherit">
                I'm currently in the process of moving from Github to my custom
                BetterTouchTool community forum to track issues.
              </Typography>
              <a
                style={{ margin: '0 auto' }}
                href="https://community.folivora.ai"
                target="_blank"
              >
                <Button
                  color="primary"
                  style={{ margin: '15px', marginLeft: '0px' }}
                >
                  <Launch style={{ marginRight: '10px' }} />
                  Go to BetterTouchTool Community Forum
                </Button>
              </a>
              <Typography component="p" color="inherit">
                The old BetterTouchTool GitHub issue tracker is available here:{' '}
                <a href="https://github.com/fifafu/BetterTouchTool">
                  github.com/fifafu/BetterTouchTool"
                </a>
                In case you have questions that don't belong on this public
                forum (e.g. license/payment related) please contact me directly
                via <strong>andreas@folivora.ai</strong>
              </Typography>
            </CardContent>
          </Card>
        </Grow>
      </div>
    );
  }
}
