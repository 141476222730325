import * as React from 'react';
import { Typography, CardContent, Card, Grow } from '@mui/material';
import './privacy.page.css';
import folivora2 from '../../images/folivora2.png';

export interface PrivacyPageProps {}

export default class PrivacyPage extends React.Component<
  PrivacyPageProps,
  any
> {
  state = {
    language: 'en'
  };

  private switchLanguage(newLanguage: any) {
    this.setState({ language: newLanguage });
  }

  render() {
    return (
      <div className="privacyPage defaultPageLayout defaultGrid">
        <img src={folivora2} className="logo" />
        <Grow in={true} {...(true ? { timeout: 1000 } : {})}>
          <Card className="firstContentBlock">
            <CardContent className="centerCardContent">
              Last Update: June, 1, 2018
              {this.state.language === 'de' && (
                <div>
                  <strong>
                    <a
                      href="#"
                      onClick={event => {
                        this.switchLanguage('en');
                        event.preventDefault();
                      }}
                    >
                      (Click here for english version)
                    </a>
                  </strong>
                  <h1 className="lightSectionTitle">Datenschutzerklärung</h1>{' '}
                  <Typography component="p" color="inherit">
                    Die Privatsphäre unserer Nutzer ist für uns sehr wichtig.
                    Deshalb sammeln wir nur die zum Betrieb und zur
                    Bestellabwicklung (inkl. Lizenzierung) notwendigen Daten und
                    stellen sicher, dass diese gut geschützt sind. Im Folgenden
                    beschreiben wir welche Daten gesammelt werden und wie diese
                    verwendet werden.
                    <br />
                    <br />
                    <strong>Inhalts & Datenschutzbeauftragter:</strong>
                    <br />
                    Andreas Hegenberg
                    <br />
                    Kontakt-Email: andreas@folivora.ai
                    <br />
                    <br />
                    <strong>Kontakt zur folivora.AI GmbH:</strong>
                    <br />
                    folivora.AI GmbH
                    <br />
                    Fasanenstraße 2
                    <br />
                    82008 Unterhaching
                    <br />
                    Tel.: +49 1515 4832755
                    <br />
                    <br />
                    <strong>
                      Daten die gesammelt werden wenn Sie diese Webseite
                      besuchen
                    </strong>
                    <br />
                    Unsere Webseite wurde vor kurzem neu geschrieben und enthält
                    nun keinerlei externe Tracking Frameworks wie z.B. Google
                    Analytics mehr. Trotzdem fallen bei einem Besuch der
                    Webseite einige Daten an die zum Teil gespeichert werden.{' '}
                    <br />
                    Bei jeder Anfrage die Ihr Web-Browser im Internet ausführt,
                    überträgt er verschiedene Informationen. Dazu gehört ihre IP
                    Addresse, das Betriebssystem, die Sprache Ihres Browsers und
                    der Typ Ihres Browsers. Außerdem ist uns natürlich der
                    Zeitpunkt des Zugriffs bekannt. Bitte kontaktieren Sie den
                    Hersteller Ihres Web-Browsers um genau herauszufinden welche
                    Daten übertragen werden.
                    <br />
                    <br />
                    Jeder Zugriff auf unseren Webserver wird in eine Logdatei
                    geschrieben. Diese Logdatei kann die gerade beschriebenen
                    Informationen enthalten. Die Logdateien werden nach 4 Wochen
                    gelöscht.
                    <br />
                    Unser Webserver wird von der Hetzner Online GmbH gehostet.
                    <br />
                    <br />
                    Wir geben diese Daten an keine anderen externen Partner
                    weiter und wir nutzen die Daten selbst nur für sehr
                    einfache, aggregierte interne Statistiken. Zum Beispiel
                    können wir sehen wieviele unterschiedliche Nutzer auf
                    unserer Webseite waren, wieviel Traffic dabei entstanden ist
                    und welche Browser verwendet wurden.
                    <br />
                    <br />
                    Für die Kommentarfunktion in unserem Blog verwenden wir seit
                    dem 17. Mai 2018 unsere selbst gehostete (in Deutschland)
                    Community Plattform, welche auf der Discourse Sofware
                    basiert. (Für Details dazu siehe Abschnitt "Daten die auf
                    der Community Plattform gesammelt werden")
                    <br />
                    <br />
                    Auf der Kaufseite wird ein externes Script unseres Resellers
                    Paddle.com eingebunden. Dadurch ist auch dieser in der Lage
                    diese Daten zu sammeln wenn der Kaufprozess gestartet wird.
                    (Siehe nächsten Abschnitt)
                    <br />
                    <br />
                    Rechtsgrundlage für die beschriebene Datenverarbeitung ist
                    Art. 6 Abs. 1 lit. b und f DSGVO.
                    <br />
                    <br />
                    <strong>
                      Daten die beim Kauf einer BetterTouchTool Lizenz gesammelt
                      werden.
                    </strong>
                    <br />
                    Die Daten die Sie beim Kauf einer BetterTouchTool Lizenz
                    eingeben (z.B. Ihren Namen, E-Mail Addresse, UST-ID etc.),
                    werden von unserem Reseller Paddle.com gespeichert und
                    unterliegen dessen Datenschutzerklärung. Paddle.com wickelt
                    den kompletten Kaufvorgang ab und ist Ihr Vertragspartner.
                    Siehe hierzu auch:{' '}
                    <a href="https://paddle.com/gdpr">
                      {'https://paddle.com/gdpr'}
                    </a>{' '}
                    und{' '}
                    <a href="https://paddle.com/privacy">
                      {'https://paddle.com/privacy'}
                    </a>
                    . Wir haben keinen Zugriff auf Ihre Zahlungsdaten
                    (Kreditkarte o.ä.).
                    <br />
                    <br />

                    Zudem laden wir während dem Bestellvorgang ein externes
                    Script von Paddles Servern, welches zur erfolgreichen
                    Bestellabwicklung benötigt wird.
                    <br />
                    <br />
                    Auf unserer Seite verwenden wir die von unserem Reseller zur
                    Verfügung gestellten Daten teilweise zur Abwicklung der
                    Bestellung. Z.B. müssen wir Ihre Lizenz erzeugen und die
                    Lizenz-Email an Sie schicken. Die Lizenzdateien enthalten
                    den Namen des Lizenzinhabers, die angegebene E-Mail Addresse
                    und eine Nummer zur Identifikation der Bestellung. Wir
                    speichern eine Kopie der Lizenzdatei um Ihnen diese
                    jederzeit wieder zur Verfügung stellen zu können. Wir nutzen
                    alle gesammelten Daten nur zur Bestellabwicklung und nicht
                    für Marketingzwecke und geben diese nicht weiter. Allerdings
                    wird zum zuverlässigen Versand der Lizensemails ein externer
                    E-Mail Service von Mailchimp.com verwendet. Die
                    Datenschutzerklärung von Mailchimp ist hier verfügbar:
                    <a href="https://mailchimp.com/legal/privacy/">
                      {'https://mailchimp.com/legal/privacy/'}
                    </a>
                    . <br />
                    Mailchimp ist in den USA ansässig, es existiert ein
                    Datenschutzabkommen mit den USA (EU-US Privacy Shield)
                    <br />
                    <br />
                    Rechtsgrundlage für die beschriebene Datenverarbeitung beim
                    Bestellprozess sind Art. 6 Abs. 1 lit. a,b,c und f DSGVO.
                    <br /> <br />
                    <strong>
                      Daten die beim Verwenden von BetterTouchTool gesammelt
                      werden.
                    </strong>
                    <br />
                    BetterTouchTool schaut regelmäßig nach ob es Updates gibt.
                    Während diesem Updatecheck werden einige einfache,
                    anonymisierte Daten übertragen. Dies beinhaltet z.B. die
                    macOS Version, die BetterTouchTool Version und das Modell
                    Ihres Macs. Diese Daten werden nur dazu genutzt
                    herauszufinden welches Update für Sie angezeigt werden
                    sollte. Z.B. macht es keinen Sinn ein Update welches
                    mindestens macOS 10.10 benötigt auf einem System mit macOS
                    10.9 anzuzeigen. Wir nutzen die Daten auschließlich für die
                    Bereitstellung der Updates. Wir speichern die übertragenen
                    Daten nicht explizit, jedoch sind sie teilweise in den Logs
                    unseres Webservers zu sehen, die Logs werden aber nach 4
                    Wochen gelöcht.
                    <br />
                    <br />
                    Nachdem ein Update erfolgreich installiert wurde meldet
                    BetterTouchTool dies mit einer eindeutigen ID, der macOS
                    Version und der BetterTouchTool Version an unseren Server.
                    Dies wird dazu genutzt zu sehen wie oft ein neues Update
                    schon installiert wurde. Dies ist wichtig, da wir sonst
                    nicht wissen können ob der Updatemechanismus richtig
                    funktioniert.
                    <br />
                    <br />
                    Zusätzlich sendet BetterTouchTool nachdem eine neue Lizenz
                    installiert wurde, einen anonymisierten, auf der eindeutigen
                    ID Ihres Macs basierenden Hash und die Identifikationsnummer
                    Ihrer Lizenz an uns. Diese Daten sind notwendig um die
                    illegale Verbreitung von BetterTouchTool Lizenzen zu
                    verhindern. Wir können aus diesen Daten auschließlich
                    herauslesen wann und auf wievielen verschiedenen Macs eine
                    bestimmte Lizenz installiert wurde.
                    <br />
                    <br />
                    Wenn die Dropbox Synchronisation von Ihnen aktiviert wird,
                    werden die BetterTouchTool Einstellungen in Ihren Dropbox
                    Account hochgeladen.
                    <br />
                    <br />
                    Rechtsgrundlage für die beschriebene Datenverarbeitung in
                    BetterTouchTool sind Art. 6 Abs. 1 lit. b und f DSGVO.
                    <br />
                    <br />
                    <strong>
                      Daten die bei der Nutzung von BTT Remote gesammelt werden
                    </strong>
                    <br />
                    Nachdem die App zum ersten mal gestartet wurde, und nach
                    jedem erfolgreichen Update, führt BTT Remote eine Anfrage an
                    unseren Webserver durch. Diese Anfrage enthält die BTT
                    Remote Version, das Model des iOS Geräts und die iOS
                    Version. Diese Daten werden nur dazu genutzt um zu sehen
                    wieviele Nutzer eine bestimmte Version installiert haben.
                    Dies ist notwendig um zu sehen ob der Updatemechanismus
                    korrekt funktioniert.
                    <br />
                    <br />
                    Wenn BTT Remote verwendet wird um eine BetterTouchTool
                    Lizenz zu erzeugen, wird der eingegebene Name und die
                    eingegebene E-Mail Addresse genutzt um die Lizenz zu
                    generieren. Die Lizenzgenerierung läuft über unsere Server,
                    es wird deshalb auch die IP Adresse des Nutzers in die
                    Logdatei des Webservers geschrieben. Diese Log Dateien
                    werden nach 4 Wochen gelöscht. Wir speichern eine Kopie der
                    generierten Lizenz um später in der Lage zu sein diese
                    wiederherzustellen falls ein Nutzer seine Lizenz verliert.
                    <br />
                    Die Lizenzdatei enthält den von Ihnen eingegebenen Namen,
                    Ihre E-Mail Addresse, einen Zeitstempel und eine ID die sich
                    auf Ihren BTT Remote Kauf von Apple bezieht.
                    <br />
                    Für den zuverlässigen Versand der generierten Lizenzen
                    verwenden wir auch hier die Services von Mailchimp.com.
                    Mailchimp ist in den USA ansässig, es existiert ein
                    Datenschutzabkommen mit den USA (EU-US Privacy Shield)
                    <br />
                    <br />
                    Rechtsgrundlage für die beschriebene Datenverarbeitung in
                    BTT Remote ist Art. 6 Abs. 1 lit. b und f DSGVO.
                    <br />
                    <br />
                    <strong>
                      Daten die bei der Verwendung von BetterSnapTool gesammelt
                      werden.
                    </strong>
                    <br />
                    Nachdem BetterSnapTool gestartet wird, ruft es eine
                    Textdatei auf unserem Webserver ab und schaut ob darin Text
                    enthalten ist der angezeigt werden soll. Dies könnte z.B.
                    dazu genutzt werden um die Nutzer zu informieren, falls der
                    Updatemechanismus nicht funktionieren sollte o.ä.. Bisher
                    war dies nie notwendig und sollte auch in Zukunft nur für
                    wichtige Informationen genutzt werden.
                    <br />
                    Während diesem Check werden technisch notwendige Daten
                    gespeichert, dazu gehört die IP Addresse und Ihre macOS
                    Version. Diese Daten werden nur für technische Zwecke
                    geloggt und nach vier Wochen gelöscht. (Rechtsgrundlage Art.
                    6 Abs. 1 lit. f DSGVO.)
                    <br />
                    <br />
                    <strong>
                      Daten die auf der Community Plattform
                      (https://community.folivora.ai) und der dazugehörigen
                      Seite https://share.folivora.ai gesammelt werden
                    </strong>
                    <br />
                    Unsere Community Plattform basiert auf den Open Source
                    Software "Discourse". Wir hosten diese selbst auf einem
                    Server der Hetzner Online GmbH in Deutschland. Es handelt
                    sich dabei um eine interaktive Plattform, auf welcher die
                    Nutzer sich Registrieren müssen um eigene Beiträge
                    einzustellen.
                    <br />
                    Beim Verwenden dieser Plattform werden an verschiedenen
                    Stellen Daten gespeichert:
                    <ul>
                      <li>
                        Beim Öffnen und Verwenden der Plattform werden IP
                        Addressen geloggt. Dies ist technisch notwendig und wird
                        zu Spambekämpfung, Sicherung und Performanceoptimierung
                        der Plattform genutzt. Die Daten werden nach spätestens
                        4 Wochen gelöscht.
                      </li>
                      <li>
                        Bei der Registrierung speichern wir die von Ihnen
                        bereitgestellten Daten.
                      </li>
                      <li>
                        Wenn Sie Beiträge auf der Plattform erstellen, auf
                        Beiträge Antworten oder Private Nachrichten schicken,
                        werden diese auf unserem Server gespeichert
                      </li>
                      <li>
                        Wenn Sie Beiträge mit "Gefällt Mir" o.ä. markieren, oder
                        anderweitig mit Beiträgen interagieren (z.B.
                        Abstimmungen).
                      </li>
                    </ul>
                    Wir verkaufen diese Daten nicht und nutzen sie nicht für
                    Marketingzwecke. Sie werden ausschließlich zum Betrieb der
                    Plattform und für interne Statistiken genutzt.
                    <br />
                    <br />
                    Zur automatischen Spambekämpfung werden wir in Zukunft
                    voraussichtlich den Service Akismet benutzen, welcher
                    Beiträge automatisch klassifizieren kann.
                    <br />
                    <br />
                    Die von Ihnen angegebene E-Mail Addresse wird ggf. benutzt
                    um Sie über neue Antworten zu den von Ihnen beobachteten
                    oder erstellten Themen zu informieren, um Ihnen Ihr Passwort
                    zu schicken, und ggf. um Sie bei rechtlich notwendigen
                    Anfragen zu kontaktieren. Zum Versand der E-Mails verwenden
                    wir den in Frankreich ansässigen Anbieter "Mailjet Inc.".
                    Wir werden die E-Mail Addressen nicht für Marketingzwecke
                    verwenden und nicht weitergeben.
                    <br />
                    Rechtsgrundlage für die beschriebene
                    Datenverarbeitungsvorgänge auf der Community Plattform sind
                    Art. 6 Abs. 1 lit. a,b,c und f DSGVO.
                    <br />
                    Sowohl community.folivora.ai als auch share.folivora.ai
                    verwenden Cookies. Allerdings nicht für Werbezwecke, sondern
                    nur für die technische Realisierung der Websiten.
                    <br />
                    <br />
                    share.folivora.ai sammelt keine zusätzlichen Nutzerdaten,
                    allerdings werden alle Presets die Sie auf dieser Seite
                    hochgeladen. Die hochgeladenen Presets können aber jederzeit
                    von Ihnen gelöscht werden (unter
                    https://share.folivora.ai/allPresets).
                    <br />
                    <strong>Weitere genutze externe Infrastruktur</strong>
                    <br />
                    Für unsere eigenen E-Mails (die wir verschicken oder
                    empfangen) verwenden wir die Dienste von Google. Google ist
                    in den USA ansässig, zwischen EU und USA existiert ein
                    Datenschutzabkommen (Privacy Shield Abkommen). Dabei nutzen
                    wir alle von Google zur Verfügung gestellten
                    Sicherheitsmechanismen.
                    <br />
                    <br />
                    <strong>Vorübergehende Speicherung von IP Adressen</strong>
                    <br />
                    In den bisherigen Abschnitten wurde mehrfach die temporäre
                    Speicherung von IP Adressen erwähnt. Diese ist notwendig, um
                    eine Auslieferung der Webseiten/Services an den Rechner des
                    Nutzers zu ermöglichen. Hierfür muss die IP-Adresse des
                    Nutzers für die Dauer der Sitzung gespeichert bleiben. Die
                    Speicherung in Logfiles erfolgt, um die Funktionsfähigkeit
                    unserer Services sicherzustellen und im Notfall Fehler
                    finden zu können. Zudem dienen uns die Daten zur
                    Sicherstellung der Sicherheit unserer Systeme. Eine
                    Auswertung der Daten zu Marketingzwecken findet nicht statt.
                    Die Rechtsgrundlage für die Speicherung ist also Art. 6 Abs.
                    1 lit. f DSGVO.
                    <strong>Auskunftsrecht</strong>
                    <br />
                    Sie können jederzeit eine Auskunft über die Daten, welche
                    wir über Sie gespeichert haben, anfordern. Schreiben Sie
                    dazu bitte eine kurze E-Mail an andreas@folivora.ai.
                    <br />
                    <br />
                    <strong>Löschrecht</strong>
                    <br />
                    Sie könnnen uns jederzeit dazu auffordern alle zu Ihnen
                    gehörenden Daten, die wir gespeichert haben, zu löschen. Wir
                    werden diesem Wunsch dann schnellstmöglich nachkommen (wenn
                    kein berechtigtes Interesse entegegensteht). Schreiben Sie
                    uns dazu einfach eine kurze E-Mail an andreas@folivora.ai
                    <br />
                    <br />
                    <strong>Korrekturrecht</strong>
                    <br />
                    Falls von uns erhobene Daten nicht korrekt sind, korrigieren
                    wir diese natürlich gerne. Schreiben Sie uns dazu einfach
                    eine kurze E-Mail an andreas@folivora.ai
                    <br />
                    <br />
                    <strong>Widerrufsrecht</strong>
                    <br />
                    Jede Datenverarbeitung die eine explizite Zustimmung
                    erfordert hat, kann jederzeit von Ihnen widerrufen werden.
                    Schreiben Sie uns dazu einfach eine kurze E-Mail an
                    andreas@folivora.ai
                    <br />
                    <strong>Weitere Fragen</strong>
                    <br />
                    Falls Sie noch weitere Fragen haben, zögern Sie nicht und
                    schreiben uns via andreas@folivora.ai. Wir werden so schnell
                    wie möglich antworten.
                    <br />
                    <br />
                    <br />
                    <strong>
                      Aktualisierungen dieser Datenschutzerklärung
                    </strong>
                    <br />
                    Wir behalten uns vor diese Datenschutzerklärung in der
                    Zukunft anzupassen. Sie werden darüber in unseren Apps oder
                    auf unserer Website informiert.
                    <br />
                    <br />
                    <strong>Externe Service Partner/Dienste</strong> <br />
                    Hier nochmal eine kurze Übersicht der verwendeten externen
                    Dienste (die Links führen zu den jeweiligen
                    Datenschutzerklärungen):
                    <ul>
                      <li>
                        <a href="https://policies.google.com/privacy?hl=de">
                          Google Apps (Mail, Kalender etc.)
                        </a>
                      </li>
                      <li>
                        <a href="https://paddle.com/privacy">
                          Paddle.com (Aktueller Reseller für BetterTouchTool)
                        </a>
                      </li>
                      
                      <li>
                        <a href="https://setapp.pl/privacy/">
                          Setapp.com (Reseller für BetterTouchTool)
                        </a>
                      </li>
                      <li>
                        <a href="https://www.mailjet.com/security-privacy/">
                          Mailjet Inc. (Sending of e-mails on the community
                          platform)
                        </a>
                      </li>
                      <li>
                        <a href="https://mailchimp.com/legal/privacy/">
                          Mailchimp.com (Versand von Lizenzemails).
                        </a>
                      </li>
                      <li>
                        <a href="https://fabric.io/terms">
                          Fabric.io (Crashlogs und anonyme Nutzungsstatistiken
                          für BetterTouchTool)
                        </a>
                      </li>
                      <li>
                        <a href="https://www.hetzner.de/rechtliches/datenschutz">
                          Hetzner Online GmbH (Hoster unserer Webpräsenz)
                        </a>
                      </li>
                      <li>
                        <a href="https://www.dropbox.com/terms#privacy">
                          Dropbox (Für die optional aktivierbare Dropbox
                          Synchronisation in BetterTouchTool)
                        </a>
                      </li>

                      <li>
                        <a href="https://imgur.com/privacy">
                          Imgur.com (Für den optional aktivierbaren Imgur
                          Screenshot Upload in BetterTouchTool)
                        </a>
                      </li>
                    </ul>
                    Mailchimp, Google, Dropbox, Fabric.io und
                    Imgur.com sind in den USA ansässig, zwischen EU und USA
                    existiert ein Datenschutzabkommen (EU-US Privacy Shield
                    Abkommen).
                  </Typography>
                </div>
              )}
              {this.state.language === 'en' && (
                <div>
                  <strong>
                    <a
                      href="#"
                      onClick={event => {
                        this.switchLanguage('de');
                        event.preventDefault();
                      }}
                    >
                      Hier klicken für deutsche Version
                    </a>
                  </strong>
                  <br /> <br />
                  <Typography component="p" color="inherit">
                    Note: this english translation is provided to inform our
                    english speaking customers. In case there are any
                    discrepancies, the German version is binding. Only the
                    German version contains all the specific information
                    required for the German DSGVO laws.
                    <br />
                  </Typography>
                  <h1 className="lightSectionTitle">Privacy Policy</h1>
                  <Typography component="p" color="inherit">
                    The privacy of our customers is very important to us and we
                    do everything to collect only the data that is really
                    necessary for order processing and for reliably running our
                    services. We make sure the data is protected using modern
                    protection strategies. In this privacy policy we describe
                    what is collected and how it is used.
                    <br />
                    <br />
                    <strong>Content & Privacy Contact:</strong>
                    <br />
                    Andreas Hegenberg
                    <br />
                    E-Mail: andreas@folivora.ai
                    <br />
                    <br />
                    <strong>Contact folivora.AI GmbH:</strong>
                    <br />
                    folivora.AI GmbH
                    <br />
                    Fasanenstraße 2
                    <br />
                    82008 Unterhaching
                    <br />
                    Tel.: +49 1515 4832755
                    <br />
                    <br />
                    <strong>Data collected when accessing this website</strong>
                    <br />
                    This website has recently been rewritten to not include any
                    third party tracking frameworks like Google Analytics.
                    However everytime you use your web browser to access a
                    website in the internet, it transmits some basic data. This
                    includes for example your IP address, your operating system,
                    the browser language, browser type and some more data. Also
                    we will know the time of the access. Please refer to your
                    Browser vendor to find more details about what data is being
                    sent.
                    <br />
                    <br />
                    Every site access is automatically written to a log file and
                    it can contain the information just described (this is the
                    standard configuration for most webservers on the internet).
                    <br />
                    Our webservers are hosted at Hetzner Online GmbH.
                    <br />
                    <br />
                    We don't give your data to any other external party. We only
                    use it for basic internal statistics which are not
                    user-specific. (E.g. the number of visitors and the amount
                    of traffic generated)
                    <br />
                    <br />
                    Starting on May 17, 2018 we use our self-hosted Community
                    Platform to display the comments in our blog. For more
                    information about this see "Data collected when using the
                    Community Platform"
                    <br />
                    <br />
                    On the purchase site we load an external script from our
                    reseller Paddle.com. This is used for the purchase process
                    (See next section)
                    <br />
                    <br />
                    <strong>
                      Data collected when purchasing BetterTouchTool.
                    </strong>
                    <br />
                    The data you enter when purchasing BetterTouchTool (e.g.
                    your name, e-mail address, vat-id etc.) are stored by our
                    reseller Paddle.com. Please see their privacy policy for
                    more information.{' '}
                    <a href="https://paddle.com/gdpr">
                      {'https://paddle.com/gdpr'}
                    </a>{' '}
                    and{' '}
                    <a href="https://paddle.com/privacy">
                      {'https://paddle.com/privacy'}
                    </a>
                    . We do not have access to your payment details (credit card
                    etc.)
                    <br />
                    <br />
                    On our side we use some of the data provided by our reseller
                    for the order fulfilment (i.e. to generate your license and
                    to send you the e-mail with your license information). We
                    store a copy of the generated license file in order to be
                    able to re-send it to you. License files contain the license
                    holder name, your e-mail address and the order
                    transaction-id. This data is only used for order fulfilment
                    and not for marketing purposes.
                    <br />
                    <br />
                    <strong>Data collected in BetterTouchTool.</strong>
                    <br />
                    BetterTouchTool does check for updates regularly. When doing
                    this it transmits basic, anonymized data about your system.
                    This includes the macOS version, the BetterTouchTool version
                    and the Mac model. This is used to give the appropriate
                    update to you. (e.g. if you are running macOS 10.9 you won't
                    receive an update that is running only on macOS 10.10). We
                    don't use this data for anything else.
                    <br />
                    <br />
                    After an update has been installed successfully,
                    BetterTouchTool posts a request to our webserver. This
                    request contains a unique id for the installation, the
                    BetterTouchTool and the macOS version. This data is only
                    used to see how many users have installed the new update.
                    <br />
                    <br />
                    Additionally when installing a new BetterTouchTool license,
                    it transmits a salted hash of your computer's uuid and the
                    transaction id of your license. This is only used to prevent
                    illegal distribution of BetterTouchTool licenses. (e.g. if a
                    license is being used on hundreds of different Macs, we can
                    see that someone must have shared it in violation with the
                    license terms). In such a case we will then disable the
                    license.
                    <br />
                    <br />
                    If you enable Dropbox Sync, your BetterTouchTool settings
                    will be uploaded to your Dropbox account.
                    <br />
                    <br />
                    <strong>Data collected in BTT Remote</strong>
                    <br />
                    After the app has been installed for the first time and
                    after every update, BTT Remote makes a request to our
                    webserver. This request contains a unique id for the
                    installation, the BTT Remote version, the device model and
                    the iOS version. This data is only used to see how many
                    users have installed the new update.
                    <br />
                    <br />
                    If BTT Remote is used to generate a BetterTouchTool license,
                    we use the entered name and e-mail to generate the license.
                    Because the license generation is done on our server, the
                    server logs will contain your IP address. These logs are
                    deleted after 4 weeks.
                    <br />
                    We save a copy of the generated license, so we can re-send
                    it in case it gets lost. The license contains the name you
                    entered, your e-mail, a timestamp and an identifier that
                    relates to your BTT Remote purchase.
                    <br />
                    For the reliable delivery of the license e-mails we use the
                    services from Mailchimp.com (they are located in the USA,
                    there is a data protection agreement between the EU an the
                    USA called the EU-US Privacy Shield)
                    <br />
                    <br />
                    <strong>Data collected in BetterSnapTool.</strong>
                    <br />
                    When launching, BetterSnapTool checks whether there are any
                    updates to show to you. This could e.g. be used to show you
                    a message if there is a bug that prevents updates. This has
                    not been used yet and will only ever be used for important
                    information.
                    <br />
                    When doing this check, BetterSnapTool asks our webserver
                    whether there are any updates to show. During this request
                    our webserver stores your IP address and your macOS version.
                    This data is not used, but it is kept in the log files for
                    four weeks. BetterSnapTool does not collect any other data.
                    <br />
                    <br />
                    <strong>
                      Data collected on the Community Platform
                      (https://community.folivora.ai) and on the connected
                      https://share.folivora.ai site.
                    </strong>
                    <br />
                    Our community platform is based on the open source software
                    "Discourse". We host it on our own server in Germany with
                    our hoster Hetzner Online GmbH. It is an interactive
                    platform on which users need to register in order to post
                    new content.
                    <br />
                    While using this platform, we collect data on multiple
                    occasions:
                    <ul>
                      <li>
                        While opening and browsing the platform your IP address
                        will be logged. This is necessary and is used to fight
                        spam, improve security and performance of the platform.
                        The data is deleted after at most 4 weeks.
                      </li>
                      <li>
                        If you create a new account we will save the data you
                        provide.
                      </li>
                      <li>
                        If you post new entries or send private messages on our
                        platform, they will be saved on our servers.
                      </li>
                      <li>
                        If you like posts, vote on posts or otherwise interact
                        with them, this interaction will be saved.
                      </li>
                    </ul>
                    We will nerver sell your data and we do not use it for
                    marketing purposes. They are only used for the operation of
                    the platform and for internal statistics.
                    <br />
                    <br />
                    For automatic spam classification we may use the Akismet
                    service in the future. That service can automatically
                    classify posts.
                    <br />
                    <br />
                    The e-mail address you provide when registering, may be used
                    to inform you about new replies to topics you are watching
                    or which you created. It may also be used to send you your
                    password, or for any cases where we are legally oblidged to
                    contact you. For the e-mails being sent via this platform,
                    we use a french service called "Mailjet Inc.". We will never
                    sell your e-mail and we will not use it for marketing
                    purposes.
                    <br />
                    We use cookies on share.folivora.ai and on
                    community.folivora.ai, however only for technical reasons
                    and not for advertisement.
                    <br />
                    <br />
                    On share.folivora.ai we do not collect additional user data,
                    however all the BetterTouchTool presets you upload to our
                    server will be saved. You can download &amp; delete them at
                    any time.
                    <br />
                    <strong>Other external infrastructure we use</strong>
                    <br />
                    For the delivery of our license emails we use the
                    Mailchimp.com service <br />
                    For our own emails (those we send and receive), we use the
                    Google services. We have activated all available security
                    enhancements offered by Google.
                    <br />
                    Both Google and Mailchimp are located in the USA. There is a
                    data protection agreement between the EU and the USA called
                    the EU-US Privacy Shield.
                    <br />
                    <br />
                    <strong>Your Data</strong>
                    <br />
                    If you want to know which personal data we have saved about
                    you please send an e-mail to andreas@folivora.ai.
                    <br />
                    <br />
                    <strong>Deletion of your Data</strong>
                    <br />
                    If you want us to delete your data please send an e-mail to
                    andreas@folivora.ai. We will then delete everything if there
                    is nothing (e.g. invoicing laws) that requires us to keep
                    the data.
                    <br />
                    <br />
                    <strong>Correction of your Data</strong>
                    <br />
                    If you want us to correct any collected data please send an
                    email to andreas@folivora.ai.
                    <br />
                    <br />
                    <strong>Revocation of data processing</strong>
                    <br />
                    Every data processing which required an explicit
                    user-agreement, can be revoked at any time. To do that send
                    a short e-mail to andreas@folivora.ai
                    <br />
                    <strong>External Services used</strong> <br />
                    Here again a quick summary of the external services we use:
                    <ul>
                      <li>
                        <a href="https://policies.google.com/privacy?hl=de">
                          Google Apps (E-Mail, Calendar etc.)
                        </a>
                      </li>
                      <li>
                        <a href="https://paddle.com/privacy">
                          Paddle.com (Current reseller for BetterTouchTool)
                        </a>
                      </li>
                     
                      <li>
                        <a href="https://setapp.pl/privacy/">
                          Setapp.com (Reseller for BetterTouchTool)
                        </a>
                      </li>
                      <li>
                        <a href="https://www.mailjet.com/security-privacy/">
                          Mailjet Inc. (Sending of e-mails on the community
                          platform)
                        </a>
                      </li>
                      <li>
                        <a href="https://mailchimp.com/legal/privacy/">
                          Mailchimp.com (Sending of license emails).
                        </a>
                        <br />
                      </li>
                      <li>
                        <a href="https://fabric.io/terms">
                          Fabric.io (Crashlogs and anonymous usage statistics in
                          BetterTouchTool)
                        </a>
                      </li>
                      <li>
                        <a href="https://www.hetzner.de/rechtliches/datenschutz">
                          Hetzner Online GmbH (Host of our websites)
                        </a>
                      </li>
                      <li>
                        <a href="https://www.dropbox.com/terms#privacy">
                          Dropbox (For the optional Dropbox sync in
                          BetterTouchTool, disabled by default)
                        </a>
                      </li>
                      <li>
                        <a href="https://help.disqus.com/terms-and-policies/disqus-privacy-policy">
                          Disqus.com (For comment section on your blog)
                        </a>
                      </li>

                      <li>
                        <a href="https://imgur.com/privacy">
                          Imgur.com (For the optional screenshot sharing in
                          BetterTouchTool, disabled by default)
                        </a>
                      </li>
                    </ul>
                    Mailchimp, Google, Fabric and Dropbox are
                    located in the USA, there is a data protection agreement
                    between the EU an the USA called the EU-US Privacy Shield)
                    <br />
                    <br />
                    <strong>Further questions</strong>
                    <br />
                    If you have any further questions, please send an email to
                    andreas@folivora.ai. We will reply as soon as possible.
                    <br />
                    <br />
                    <strong>Updates to this privacy policy</strong>
                    <br />
                    There may be updates to this privacy policy in the future.
                    You will be notified about them within my apps or on my
                    blog.
                    <br />
                    <br />
                  </Typography>
                </div>
              )}
            </CardContent>
          </Card>
        </Grow>
      </div>
    );
  }
}
