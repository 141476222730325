import * as React from "react";
import { Link, Route, Routes } from "react-router-dom";

import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogContent,
  Grow,
  Slide,
  Typography,
} from "@mui/material";
import folivora2 from "../../images/folivora2.png";
import withRouter from "../../withRouter";
import BlogPostComponent from "./blog-post/blog-post.component";
import "./blog.page.css";
declare var window: any;

class BlogPage extends React.Component<any, any> {
  public constructor(props: any, state: any) {
    super(props, state);
    this.state = {
      data: [],
      currentPage: 0,
      currentCommentConfig: {},
      commentModalVisible: false,
      didLoadBlogData: false,
    };
  }

  loadComments() {
    window.DiscourseEmbed = {
      discourseUrl: "https://community.folivora.ai/",
      discourseEmbedUrl:
        "https://folivora.ai/blog/post/" +
        this.state.currentCommentConfig.identifier,
    };

    console.log("load posts", window.DiscourseEmbed.discourseEmbedUrl);

    (function () {
      var d = document.createElement("script");
      d.type = "text/javascript";
      d.async = true;
      d.src = window.DiscourseEmbed.discourseUrl + "javascripts/embed.js";
      (
        document.getElementsByTagName("head")[0] ||
        document.getElementsByTagName("body")[0]
      ).appendChild(d);
    })();
  }

  getPostLink() {
    return "";
  }

  loadData() {
    fetch("blog_overview.json")
      .then((response) => response.json())
      .then((json) => {
        this.setState({ didLoadBlogData: true });
        if (this.state.postName) {
          console.log("postname: ", this.state.postName);
          console.log(this.state.postName);
          for (const post of json) {
            if (post.link === this.state.postName) {
              this.setState({
                data: [post],
              });
              if (this.props.showComments) {
                console.log('show comments', this.props);

                this.setState({ previousURL: this.props.location.pathname });

                this.setState({
                  currentCommentConfig: {
                    url: "https://folivora.ai/blog/post/" + post.id,
                    identifier: post.id,
                    title: post.title,
                  },
                  commentModalVisible: true,
                });
                this.loadComments();
              } else {
                this.setState({
                  commentModalVisible: false,
                });
              }
              break;
            }
          }
        } else if (this.state.postId) {
          console.log("props", this.props);
          for (const post of json) {
            if (post.id === this.state.postId) {
              this.setState({
                data: [post],
              });

              if (this.props.showComments) {
                console.log("show comments", this.props);
                this.setState({
                  previousURL: this.props.router.location.pathname,
                });

                this.setState({
                  currentCommentConfig: {
                    url:
                      "https://folivora.ai/blog/post" + post.id + "/comments",
                    identifier: post.id,
                    title: post.title,
                  },
                  commentModalVisible: true,
                });
                this.loadComments();
              } else {
                this.setState({
                  commentModalVisible: false,
                });
              }
              break;
            }
          }
        } else {
          this.setState({
            data: json,
          });
        }
      });
  }

  private updatePropsAndLoadData() {
    console.log(this.props);

    let pageNumber = parseInt(this.props.router.params.pageNumber, 10);
    let postId = parseInt(this.props.router.params.postId, 10);
    let postName = this.props.router.params.unknownPageName;

    if (postName && postName.length > 0) {
      this.setState({ postName });
    } else {
      this.setState({ postName: undefined });
    }

    if (postId && postId > 0) {
      this.setState({ postId });
    } else {
      this.setState({ postId: undefined });
    }

    if (pageNumber && pageNumber > 0) {
      this.setState({ currentPage: pageNumber });
    } else {
      this.setState({ currentPage: 0 });
    }
    this.loadData();
  }
  componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
    console.log("did update", this.props);
    if (
      this.props.router.params.postId !== prevProps.router.params.postId ||
      this.props.router.params.pageNumber !==
        prevProps.router.params.pageNumber ||
      this.props.router.params.unknownPageName !==
        prevProps.router.params.unknownPageName || this.props.showComments !== prevProps.showComments
    ) {
      this.updatePropsAndLoadData();
    }
  }

  componentDidMount() {
    this.updatePropsAndLoadData();
  }

  private slideTransition(props: any) {
    return <Slide direction="up" {...props} />;
  }

  public showDiscusModal(post: any) {
    if (post.id) {
      this.props.router.navigate("/blog/post/" + post.id + "/comments");
    } else if (post.link) {
      this.props.router.navigate("/blog/" + post.link + "/comments");
    }
  }

  render() {
    // hidden posts are basically just website pages that do not belong to the blog.
    // I'm just using this as a hack so I can easily create additional pages
    // when necessary.
    const postsWithoutHiddenPosts = this.state.data.filter((post: any) => {
      return !post.hide || this.state.postName;
    });
    let posts = postsWithoutHiddenPosts.slice(
      this.state.currentPage * 5,
      this.state.currentPage * 5 + 5
    );

    return (
      <div className="blogPage defaultPageLayout defaultGrid">
        <img src={folivora2} className="logo" />
        {!this.state.didLoadBlogData ? <CircularProgress size={50} /> : ""}
        {this.state.didLoadBlogData &&
        this.state.postName &&
        (!posts || posts.length <= 0) ? (
          <Grow in={true} {...(true ? { timeout: 1000 } : {})}>
            <Card className="firstContentBlock">
              <CardContent>
                Unfortunately the page you are looking for doesn't seem to exist
                anymore. Maybe it got lost during migration. If you have any
                questions, please contact me via andreas@folivora.ai
              </CardContent>
            </Card>
          </Grow>
        ) : (
          ""
        )}
        {posts.map((data: any, key: any) => {
          return (
            <Grow key={key} in={true} {...(true ? { timeout: 1000 } : {})}>
              <Card className="firstContentBlock">
                <CardContent>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      paddingBottom: "5px",
                      borderBottom: "1px solid #ccc",
                    }}
                  >
                    <Link className="link" to={"/blog/post/" + data.id}>
                      <h2 className="lightSectionTitle">{data.title}</h2>
                    </Link>
                    <Typography component="p">
                      Posted on{" "}
                      {new Date(data.date).toLocaleDateString("en-EN", {
                        weekday: "long",
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </Typography>
                    {posts && posts.length === 1 ? (
                      <Link className="link" to="/blog/">
                        ◀︎ Go back to all posts
                      </Link>
                    ) : (
                      ""
                    )}
                    <a
                      style={{
                        marginLeft: "auto",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        this.showDiscusModal(data);
                        e.stopPropagation();
                        e.nativeEvent.stopImmediatePropagation();
                      }}
                    >
                      Comments
                    </a>
                  </div>
                  <BlogPostComponent
                    postId={data.id}
                    format={data.format ? data.format : "html"}
                  />
                </CardContent>
              </Card>
            </Grow>
          );
        })}
        {!this.state.postId && !this.state.postName ? (
          <Grow in={true} {...(true ? { timeout: 1000 } : {})}>
            <Card className="firstContentBlock">
              <CardContent style={{ display: "flex" }}>
                {this.state.currentPage > 0 ? (
                  <Button
                    color="primary"
                    style={{ margin: "15px", alignSelf: "flex-start" }}
                    onClick={() => {
                      window.scrollTo(0, 0);
                      this.props.router.navigate(
                        "/blog/page/" + (this.state.currentPage - 1)
                      );
                    }}
                  >
                    Previous Page
                  </Button>
                ) : (
                  ""
                )}
                <Button
                  data-href={"/blog/page/" + (this.state.currentPage + 1)}
                  color="primary"
                  style={{ margin: "15px", marginLeft: "auto" }}
                  onClick={() => {
                    window.scrollTo(0, 0);

                    this.props.router.navigate(
                      "/blog/page/" + (this.state.currentPage + 1)
                    );
                  }}
                >
                  Next Page
                </Button>
              </CardContent>
            </Card>
          </Grow>
        ) : (
          ""
        )}
        <Dialog
          maxWidth="md"
          TransitionComponent={this.slideTransition}
          open={this.state.commentModalVisible}
          onClose={() => {
            this.props.router.navigate(-1);
            this.setState({ commentModalVisible: false });
          }}
        >
          <DialogContent style={{ width: "800px" }}>
            <p style={{ fontSize: 12, color: "#ccc" }}>
              The displayed comments are hosted on our BetterTouchTool community
              forum: {"https://community.folivora.ai"}
            </p>
            <div id="discourse-comments" />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default withRouter(BlogPage);
