import * as React from 'react';
import './card.component.css';
import { Card, CardContent, Typography } from '@mui/material';

export interface CardComponentInterface {
  date: Date;
  title: string;
  short_desc: string;
  onClick: Function;
}
class CardComponent extends React.Component<CardComponentInterface, any> {
  render() {
    // const content = this.props.content;
    return (
      <Card
        className="faiCard"
        elevation={0}
        onClick={() => {
          this.props.onClick();
        }}
      >
        <CardContent>
          <Typography color="inherit" style={{ fontSize: '0.8rem' }}>
            {new Date(this.props.date).toLocaleDateString('en-EN', {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}
          </Typography>
          <Typography
            component="h3"
            color="inherit"
            style={{ fontSize: '1.2rem' }}
          >
            {this.props.title}
          </Typography>
          <Typography component="p" style={{ fontSize: '0.95rem' }} color="inherit">
            {this.props.short_desc}
          </Typography>
        </CardContent>
      </Card>
    );
  }
}

export default CardComponent;
