import * as React from 'react';
import './footer.css';
import { Button } from '@mui/material';
import withRouter, { WithRouterProps } from '../../withRouter';

class FooterComponent extends React.Component<any> {
  render() {
    // const content = this.props.content;
    return (
      <footer className="footerComponent">
        <Button
          color="primary"
          onClick={() => 
            this.props.router.navigate('/privacy')}
        >
          Privacy Policy
        </Button>
        <Button
          color="primary"
          onClick={() => this.props.router.navigate('/buy')}
        >
          License Terms / Terms of Service
        </Button>
        <Button
          color="primary"
          onClick={() => this.props.router.navigate('/imprint')}
        >
          Imprint / Contact
        </Button>
        <Button
          color="primary"
          onClick={() => this.props.router.navigate('/licenses')}
        >
          Open Source Licenses Used
        </Button>
      </footer>
    );
  }
}

export default withRouter(FooterComponent);
