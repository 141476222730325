import * as React from 'react';
import { Typography, CardContent, Card, Grow, Button } from '@mui/material';
import './docs.page.css';
import folivora2 from '../../images/folivora2.png';
import { Launch } from '@mui/icons-material';

export interface DocsPageProps {}

export default class DocsPage extends React.Component<DocsPageProps, any> {
  render() {
    return (
      <div className="docsPage defaultPageLayout defaultGrid">
        <img src={folivora2} className="logo" />
        <Grow in={true} {...(true ? { timeout: 1000 } : {})}>
          <Card className="firstContentBlock">
            <CardContent>
              <h1 className="lightSectionTitle">Documentation</h1>

              <Typography component="p" color="inherit">
               The current version of the
                BetterTouchTool documentation is available here:
              </Typography>
              <a
                style={{ margin: '0 auto' }}
                href="https://docs.folivora.ai"
              >
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: '15px', marginLeft: '0px' }}
                >
                  <Launch style={{ marginRight: '10px' }} />
                  Open Documentation
                </Button>
              </a>

              <Typography component="p" color="inherit">
                Additionaly I'd recommend to look into the BetterTouchTool
                community page where people can share tutorials, presets and
                discuss everything about BetterTouchTool
              </Typography>
              <a
                style={{ margin: '0 auto' }}
                href="https://community.folivora.ai"
              >
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: '15px', marginLeft: '0px' }}
                >
                  <Launch style={{ marginRight: '10px' }} />
                  Go to community.folivora.ai
                </Button>
              </a>
            </CardContent>
          </Card>
        </Grow>
      </div>
    );
  }
}
