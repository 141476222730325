import * as React from 'react';
import folivora2 from '../../images/folivora2.png';
import { Card, CardContent, Typography, Grow, Button } from '@mui/material';
import keyboardCleanTool from '../../images/keyboarcleantool_app.png';
import './keyboardcleantool.page.css';
import keyboardcleantool from '../../images/keyboardcleantool.png';
import { GetApp } from '@mui/icons-material';

export default class KeyboardCleanToolPage extends React.Component {
  render() {
    // const content = this.props.content;
    return (
      <div className="keyboardCleanToolPage defaultPageLayout defaultGrid">
        <img src={folivora2} className="logo" />
        <Grow in={true} {...(true ? { timeout: 1000 } : {})}>
          <Card className="firstContentBlock">
            <CardContent className="centerCardContent">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={keyboardcleantool}
                  style={{ height: '50px', marginRight: '10px' }}
                />

                <h1 className="lightSectionTitle">KeyboardCleanTool</h1>
              </div>{' '}
              <Typography component="p">
                KeyboardCleanTool is a super simple little tool which blocks all
                Keyboard and TouchBar input.
                <br /> <br />
                Years ago Apple rejected the app for the Mac App Store because
                apparently it's "not useful", however I often use it to clean my
                Macbook Keyboard without producing annoying input.
                <br />
                <br />
                I have also heard of people who use it to let their toddlers
                pretend they work on a computer.
                <br />
                Also people with cats.
              </Typography>
              <a
                style={{ margin: '0 auto' }}
                href="https://folivora.ai/releases/KeyboardCleanTool.zip"
                target="_blank"
              >
                <Button variant="contained" color="primary">
                  <GetApp style={{ marginRight: '10px' }} />
                  Free Download
                </Button>
              </a>
            </CardContent>
          </Card>
        </Grow>
        <Grow in={true} {...(true ? { timeout: 1000 } : {})}>
          <Card className="secondContentBlock">
            <CardContent style={{ textAlign: 'center' }}>
              <img src={keyboardCleanTool} width="672px" />
            </CardContent>
          </Card>
        </Grow>
      </div>
    );
  }
}
