import * as React from "react";
import "./blog-post.component.css";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from 'remark-gfm'

import rehypePrism from "@mapbox/rehype-prism";
export interface BlogPostComponentProps {
  postId: number;
  format: "md" | "html";
}

export default class BlogPostComponent extends React.Component<
  BlogPostComponentProps,
  any
> {
  public constructor(props: any, state: any) {
    super(props, state);
    this.state = {
      data: "",
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
    console.log("did update component", this.props);
    if (prevProps.postId !== this.props.postId) {
      this.loadData();
    }
  }

  loadData() {
    const url =
      "posts/" +
      this.props.postId +
      (this.props.format === "md" ? ".md" : ".html");
    console.log("loading url ", url, this.props);
    fetch(url)
      .then((response: any) => response.text())
      .then((text: any) => {
        this.setState({
          data: text,
        });
      });
  }
  render() {
    return this.props.format === "html" ? (
      <div dangerouslySetInnerHTML={{ __html: this.state.data }} />
    ) : (
      <ReactMarkdown
        children={this.state.data}
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypePrism, rehypeRaw ]}
        components={{
          // Rewrite img
          img: ({ node, ...props }) => (
            // eslint-disable-next-line jsx-a11y/alt-text
            <a href="#img"  onClick={(e) => {
              let lightbox = (window as any).lightbox as any;
              lightbox.setElements([
                {
                  width:'85vw',
                  href: props.src,
                  type: "image",
                },
              
              ]);

              lightbox.open();
              e.preventDefault();
              e.stopPropagation();
            }}>
            <img className="markdownimage"
             alt={props.alt}
              {...props}
            />
            </a>
          ),
        }}
      />
    );
  }
}
