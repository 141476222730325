import * as React from 'react';
import { Dialog, Slide } from '@mui/material';
import './thumbnailbox.component.css';

export default class ThumbnailboxComponent extends React.Component<
  AnyProps,
  any
> {
  static defaultProps = {
    fullsizeImageStyle: {
      objectFit: 'contain',
      width: '100%',
      padding: '10px',
    },
  };

  constructor(props: any) {
    super(props);
    this.state = {
      modalVisible: false,
    };
  }

  private showModal() {
    this.setState({
      modalVisible: true,
    });
  }

  private slideTransition(props: any) {
    return <Slide direction="up" {...props} />;
  }

  private closeModal() {
    this.setState({
      modalVisible: false,
    });
  }

  render() {
    const {
      fullsizeImageStyle,
      fullsizeImage,
      thumbnailImage,
      ...rest
    } = this.props;

    return (
      <div className="thumbnailBox" {...rest}>
        <img
          className="thumbnailbox"
          width="100%"
          src={this.props.thumbnailImage}
          onClick={() => {
            this.showModal();
          }}
        />

        <Dialog
          maxWidth="md"
          TransitionComponent={this.slideTransition}
          open={this.state.modalVisible}
          onClose={() => {
            this.closeModal();
          }}
        >
          <img
            style={this.props.fullsizeImageStyle}
            src={this.props.fullsizeImage}
            onClick={() => {
              this.showModal();
            }}
          />
        </Dialog>
      </div>
    );
  }
}
